<template>
    <default-template
        title="追加利用者登録確認"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">追加利用者登録確認</h2>
                <hr class="hp_flexspacer"/>
                <p>以下の利用者を登録します。よろしいですか？</p>
            </div>
            <div class="uq_userCreateBox">
                <div class="formrow bl_input_container">
                    <app-label
                        name="organization_name"
                        label="事業者名"
                    />
                    <p>{{organization.name}}</p>
                </div>
                <user-confirm />
                <div class="formrow bl_input_container">
                    <app-label
                        name="organization_name"
                        label="事業者管理権限"
                    />
                    <p>なし</p>
                </div>
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="戻る"
                    @click="moveTo('/organization/users/create')"
                    ref="jest-user-create-back"
                    class="cancel"
                />
                <app-button
                    name="確定"
                    @click="submit"
                    ref="jest-user-create-submit"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P122 事業者情報編集
 *
 */
import UserConfirm from '@/components/organisms/UserConfirm'
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'

export default {
    name:'OrganizationUserCreateConfirm',
    components:{
        UserConfirm,
    },
    computed:{
        ...mapGetters({
            organization:'auth/organization',
        }),
        isHouse(){
            return this.organization.type == 1
        },
    },
    methods:{
        ...mapActions({
            createUser:'register/user/createUser',
            clearError:'error/clear',
            updateError:'error/update',
        }),
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        },
        submit(){
            this.clearError()
            this.createUser()
                .then(() => {
                    move(this.$router, '/organization/users/create/complete')
                }, reject => {
                    this.updateError(reject.response)
                    move(this.$router, '/organization/users/create')
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.uq_userCreateBox{
    padding: 16px 22px;
    border: 1px solid #CDD6DD;
}
.bl_input_container{
    padding: 4px 0;
}
.uq_userCreateBox >>> .form_box {
    width: 100%;
    padding: 0;
    border:none;
}
div.uq_userCreateBox>div.bl_input_container,
div.uq_userCreateBox >>> .form_box div.formrow{
    display: grid;
    grid-template-columns: 9em minmax(300px,1fr);
    border-bottom: 1px solid #F1F4EC;
}
div.uq_userCreateBox>div.bl_input_container p{
    margin: 0;
}
</style>