<template>
    <default-template
        title="追加利用者登録完了"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">追加利用者登録完了</h2>
                <hr class="hp_flexspacer"/>
            </div>
            <div class="bl_msgBox">
                <p>追加利用者の登録が完了しました。<br>
                登録いただきましたメールアドレスあてに確認メールを送信いたしました。</p>
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="完了"
                    @click="moveTo('/organization/organization')"
                    ref="jest-user-create-finish"
                    class="btn_center"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P122 事業者情報編集
 *
 */
import { mapGetters } from 'vuex'
import { move } from '@/util'

export default {
    name:'OrganizationUserCreateConfirm',
    components:{
    },
    computed:{
        ...mapGetters({
            organization:'auth/organization',
        }),
        isHouse(){
            return this.organization.type == 1
        },
    },
    methods:{
        moveTo(path){
            move(this.$router, path)
        },
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
</style>