<template>
    <div class="form_box ly_form_box ly_userForm">
        <div class="formrow">
            <app-label
                name="division"
                label="担当部署"
            />
            <p>{{division}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="name"
                label="担当者名"
                class=""
            />
            <p ref="jest-user-name">{{name}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="tel"
                label="担当者電話番号"
            />
            <p>{{tel}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="email"
                label="メールアドレス"
                class=""
            />
            <p>{{email}}</p>
        </div>
        <div
            class="formrow"
            v-if="isNew"
        >
            <app-label
                name="password"
                label="パスワード"
                class=""
            />
            <p>{{maskedPassword}}</p>
        </div>
    </div>
</template>

<script>
/**
 * ユーザ情報の入力内容確認
 *
 * [算出プロパティ]
 * maskedPassword ... 文字数をpasswordと合わせた「*」の羅列
 */
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'

export default {
    name:'UserConfirm',
    props:{
        isNew:{
            type:Boolean,
            default:true
        }
    },
    computed:{
        ...mapGetters({
            data:'register/user/getAll',
        }),
        ...mapComputedProperties([
            'division',
            'name',
            'tel',
            'email',
            'email_confirmation',
            'password',
            'password_confirmation',
        ]),
        // 文字数をpasswordと合わせた「*」の羅列
        maskedPassword(){
            return '*'.repeat(this.password.length)
        }
    },
    methods:{
        ...mapActions({
            update:'user/update'
        })
    }
}
</script>

<style scoped>
.ly_userForm.ly_form_box >>> div.common_text_column, .ly_userForm.form_box >>> div.formrow {
    grid-template-columns: 8em minmax(300px,1fr);
    -ms-grid-columns: 8em minmax(300px,1fr);
}
</style>