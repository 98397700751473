<template>
    <div class="form_box ly_form_box ly_userForm">
        <app-text
            name="division"
            v-model="division"
            label="担当部署"
            class="formrow"
            text-box-id="user_division"
        />
        <app-text
            name="name"
            v-model="name"
            :lbl_required="true"
            label="担当者名"
            class="formrow"
        />
        <app-text
            name="tel"
            v-model="tel"
            label="担当者電話番号"
            class="formrow"
        />
        <app-text
            name="email"
            v-model="email"
            :lbl_required="true"
            label="メールアドレス"
            class="formrow mail_address"
        />
        <app-text
            name="email_confirmation"
            v-model="email_confirmation"
            :lbl_required="true"
            label="メールアドレス（確認）"
            class="formrow mail_address"
        />
        <div
            class="formrow"
            v-if="isNew"
        >
            <app-label
                name="password"
                label="パスワード"
                class="lbl_required"
            />
            <div class="rowcontents">
                <input
                    type="password"
                    name="password"
                    v-model="password"
                    class="uq_pre_subscript"
                />
                <span class="uq_subscript">半角英数6文字以上を入力してください</span>
            </div>
        </div>
        <div
            class="formrow"
            v-if="isNew"
        >
            <app-label
                name="password_confirmation"
                label="パスワード（確認）"
                class="lbl_required"
            />
            <input
                type="password"
                name="password_confirmation"
                v-model="password_confirmation"
                class="rowcontents"
            />
        </div>
    </div>
</template>

<script>
/**
 * ユーザ情報の入力フォーム
 */
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'

export default {
    name:'UserForm',
    props:{
        isNew:{
            type:Boolean,
            default:true
        }
    },
    computed:{
        ...mapGetters({
            data:'register/user/getAll',
        }),
        ...mapComputedProperties([
            'division',
            'name',
            'tel',
            'email',
            'email_confirmation',
            'password',
            'password_confirmation',
        ])
    },
    methods:{
        ...mapActions({
            update:'register/user/update'
        })
    }
}
</script>

<style scoped>
.uq_pre_subscript{
    margin-right: 16px;
}
.uq_subscript{
    white-space: nowrap;
}
</style>