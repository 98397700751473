<template>
    <default-template
        title="ユーザー情報変更"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">ユーザー情報変更</h2>
                <hr class="hp_flexspacer"/>
                <p>登録情報を入力してください。</p>
            </div>
            <error-list />
            <div class="uq_userEditBox">
                <div class="formrow bl_input_container">
                    <app-label
                        name="organization_name"
                        label="事業者名"
                    />
                    <p>{{organization.name}}</p>
                </div>
                <user-form :isNew="false" />
                <div class="formrow bl_input_container">
                    <app-label
                        name="organization_name"
                        label="事業者管理権限"
                    />
                    <p>{{user.is_organization_administrator ? 'あり' : 'なし'}}</p>
                </div>
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="moveTo('/organization/organization')"
                    ref="jest-user-create-cancel"
                    class="cancel"
                />
                <app-button
                    name="入力内容確認"
                    @click="confirm"
                    ref="jest-user-create-confirm"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P123_利用者情報変更
 *
 */
import ErrorList from '@/components/molecules/ErrorList'
import UserForm from '@/components/organisms/UserForm'
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'

export default {
    name:'OrganizationUsersEdit',
    components:{
        ErrorList,
        UserForm,
    },
    mounted(){
        scroll(0, 0)
        if(document.getElementById('user_division') != null){
            document.getElementById('user_division').focus()
        }
    },
    computed:{
        ...mapGetters({
            prevalidate:'register/user/prevalidateEmail',
            prevalidateError:'register/user/prevalidateEmailError',
            user:'register/user/getAll',
            organization:'auth/organization',
        }),
        isHouse(){
            return this.organization.type == 1
        },
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
        }),
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        },
        confirm(){
            this.clearError()
            if(this.prevalidate) {
                move(this.$router, '/organization/users/' + this.user.id +'/edit/confirm')
            } else {
                this.updateError({
                    status:422,
                    data:{
                        errors:this.prevalidateError
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.uq_userEditBox{
    padding: 16px 22px;
    border: 1px solid #CDD6DD;
}
.bl_input_container{
    padding: 4px 0;
}
.uq_userEditBox >>> .form_box {
    width: 100%;
    padding: 0;
    border:none;
}
div.uq_userEditBox>div.bl_input_container,
div.uq_userEditBox >>> .form_box div.formrow{
    display: grid;
    grid-template-columns: 15em minmax(300px,1fr);
    border-bottom: 1px solid #F1F4EC;
}
div.uq_userEditBox>div.bl_input_container p{
    margin: 0;
}
</style>