<template>
    <default-template
        title="追加利用者登録"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">追加利用者登録</h2>
                <hr class="hp_flexspacer"/>
                <p>登録情報を入力してください。パスワードは半角英数6文字以上を入力してください。</p>
            </div>
            <error-list />
            <div class="uq_userCreateBox">
                <div class="formrow bl_input_container">
                    <app-label
                        name="organization_name"
                        label="事業者名"
                    />
                    <p>{{organization.name}}</p>
                </div>
                <user-form />
                <div class="formrow bl_input_container">
                    <app-label
                        name="organization_name"
                        label="事業者管理権限"
                    />
                    <p>なし</p>
                </div>
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="moveTo('/organization/organization')"
                    ref="jest-user-create-cancel"
                    class="cancel"
                />
                <app-button
                    name="入力内容確認"
                    @click="confirm"
                    ref="jest-user-create-confirm"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P121 事業者情報変更
 *
 */
import ErrorList from '@/components/molecules/ErrorList'
import UserForm from '@/components/organisms/UserForm'
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'

export default {
    name:'OrganizationUsersCreate',
    components:{
        ErrorList,
        UserForm,
    },
    mounted(){
        scroll(0, 0)
        if(document.getElementById('user_division') != null){
            document.getElementById('user_division').focus()
        }
    },
    computed:{
        ...mapGetters({
            prevalidate:'register/user/prevalidate',
            prevalidateError:'register/user/prevalidateError',
            organization:'auth/organization',
        }),
        isHouse(){
            return this.organization.type == 1
        },
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
        }),
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        },
        confirm(){
            this.clearError()
            if(this.prevalidate) {
                move(this.$router, '/organization/users/create/confirm')
            } else {
                this.updateError({
                    status:422,
                    data:{
                        errors:this.prevalidateError
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.uq_userCreateBox{
    padding: 16px 22px;
    border: 1px solid #CDD6DD;
}
.bl_input_container{
    padding: 4px 0;
}
.uq_userCreateBox >>> .form_box {
    width: 100%;
    padding: 0;
    border:none;
}
div.uq_userCreateBox>div.bl_input_container,
div.uq_userCreateBox >>> .form_box div.formrow{
    display: grid;
    grid-template-columns: 15em minmax(300px,1fr);
    border-bottom: 1px solid #F1F4EC;
}
div.uq_userCreateBox>div.bl_input_container p{
    margin: 0;
}
.ly_userform{
    width: 100%;
}
</style>