<template>
    <default-template
        title="ユーザー情報変更確認"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">ユーザー情報変更確認</h2>
                <hr class="hp_flexspacer"/>
                <p>以下の利用者を更新します。よろしいですか？</p>
            </div>
            <div class="uq_userEditBox">
                <div class="formrow bl_input_container">
                    <app-label
                        name="organization_name"
                        label="事業者名"
                    />
                    <p>{{organization.name}}</p>
                </div>
                <user-confirm :isNew="false" />
                <div class="formrow bl_input_container">
                    <app-label
                        name="organization_name"
                        label="事業者管理権限"
                    />
                    <p>{{user.is_organization_administrator ? 'あり' : 'なし'}}</p>
                </div>
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="戻る"
                    @click="moveTo('/organization/users/' + user.id + '/edit')"
                    ref="jest-user-create-back"
                    class="cancel"
                />
                <app-button
                    name="確定"
                    @click="submit"
                    ref="jest-user-create-submit"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P124_利用者情報変更確認
 *
 */
import UserConfirm from '@/components/organisms/UserConfirm'
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'

export default {
    name:'OrganizationUserCreateConfirm',
    components:{
        UserConfirm,
    },
    computed:{
        ...mapGetters({
            organization:'auth/organization',
            user:'register/user/getAll',
        }),
        isHouse(){
            return this.organization.type == 1
        },
    },
    methods:{
        ...mapActions({
            updateUser:'register/user/updateUser',
            clearError:'error/clear',
            updateError:'error/update',
        }),
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        },
        submit(){
            this.clearError()
            this.updateUser()
                .then(() => {
                    move(this.$router, '/organization/organization')
                }, reject => {
                    this.updateError(reject.response)
                    move(this.$router, '/organization/users/' + this.user.id + '/edit')
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.uq_userEditBox{
    padding: 16px 22px;
    border: 1px solid #CDD6DD;
}
.bl_input_container{
    padding: 4px 0;
}
.uq_userEditBox >>> .form_box {
    width: 100%;
    padding: 0;
    border:none;
}
div.uq_userEditBox>div.bl_input_container,
div.uq_userEditBox >>> .form_box div.formrow{
    display: grid;
    grid-template-columns: 9em minmax(300px,1fr);
    border-bottom: 1px solid #F1F4EC;
}
div.uq_userEditBox>div.bl_input_container p{
    margin: 0;
}
</style>